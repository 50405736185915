import { FunctionComponent, useEffect } from "react";
import { Link } from "react-router-dom";
import Pdf from "../../assets/docs/MenuNoche.pdf";
import { IDish } from "../../models/IDish";
import { FOOD_CATEGORIES } from "../FoodsComponent/consts";
import styles from "./PopularDishes.module.scss";

interface PopularDishesComponentProps {}

const PopularDishesComponent: FunctionComponent<
  PopularDishesComponentProps
> = () => {
  const getRandomDishes = () => {
    let allProducts: IDish[] = [];
    const randomDishes: IDish[] = [];
    FOOD_CATEGORIES.forEach((category) => {
      const { productsList } = category;
      if (!allProducts.length) {
        allProducts = [...productsList];
      } else {
        allProducts = allProducts.concat(productsList);
      }
    });

    while (randomDishes.length != 4) {
      const index = Math.floor(Math.random() * allProducts.length);
      const dish = allProducts[index];
      if (!randomDishes.includes(dish)) {
        randomDishes.push(dish);
      }
    }

    return randomDishes;
  };

  useEffect(() => {
    getRandomDishes();
  });

  return (
    <>
      <section className="menu" id="menu">
        <div className="main-text">
          <h2>Sugeridos de la casa</h2>
        </div>

        <div className="menu-content">
          {getRandomDishes().map((dish: IDish) => (
            <div className="row transform duration-700 hover:shadow-xl hover:scale-105 p-4 rounded-lg relative">
              <div className={styles.imgContainer}>
                <img src={dish.image} />
              </div>

              <div className="menu-text">
                <div className="menu-left">
                  <h4>{dish.name}</h4>
                </div>
                <div className="menu-right">
                  <h5>${dish.price}</h5>
                </div>
              </div>
              <div className={styles.dishDescription}>
                <p>{dish.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.actionButton}>
          <Link to={"/menu"} className="btn backgroundRed">
            Menú día <span> Disponible todos los dias de 11.30am a 5pm</span>
          </Link>
          <Link to={Pdf} className="btn backgroundBlue" target="_blank">
            Menú noche
            <span> Disponible de jueves a domingo de 7pm a 11pm</span>
          </Link>
        </div>
      </section>
    </>
  );
};

export default PopularDishesComponent;
