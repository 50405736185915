import { FunctionComponent } from "react";
import classNames from "classnames";
import styles from "./FooterComponent.module.css";

interface FooterComponentProps {}

const FooterComponent: FunctionComponent<FooterComponentProps> = () => {
  return (
    <>
      <footer className={classNames(styles.footerBg)}>
        <div>
          <p>
            <a className={styles.mapLink} href="https://www.google.com/maps/place/Origen+mesa+y+bar/@4.3276749,-74.3919599,15z/data=!4m6!3m5!1s0x8e3f05ddf8fa019b:0x5558f40bc48dc95c!8m2!3d4.3276749!4d-74.3919599!16s%2Fg%2F11v5g1qtm2?entry=ttu" target="_blank">
              Calle 25 # 48 - 50, Fusagasugá - Cundinamarca </a>
            <br />
            Lunes a Domingo: 11.30am - 05.00 pm<br />
            Reservas y domicilios: 
            <a className={styles.whatsapp} href="https://api.whatsapp.com/send?phone=573045317663" target="_blank">3045317663</a>  <br />© 2023
          </p>
        </div>
      </footer>
    </>
  );
};

export default FooterComponent;
