import { CategoryEnum, ICategory } from "../../models/ICategory";
import { IDish } from "../../models/IDish";
import {
  ArrozCartageneroImg,
  CazuelaMariscosImg,
  ChurrascoImg,
  ColaResImg,
  IntantilImg,
  LomoCaciqueImg,
  LomoMarTierraImg,
  OssobucoImg,
  ParrilladaImg,
  SalmonImg,
  TruchaImg,
  ViudoImg,
  CazuelitasCamaronImg,
  CazuelitasHogaoImg,
  LomoCerdoImg,
  MojarraImg,
  PechugaChampinonImg,
  PechugaParrillaImg,
  SobrebarrigaImg,
  TruchaParrillaImg,
  AjiacoImg,
  MojarraSalsaImg,
  CocidoBoyacenseImg,
  CostillasImg,
  MuteImg,
  LenguaSalsaImg,
  BagreSalsaImg,
  CevicheChicharronImg,
  ChurrascoOrigen,
} from "../../assets/images/products";

const ENTRADAS: Array<IDish> = [
  {
    id: `${CategoryEnum.ENTRADAS} - 1`,
    image: CazuelitasHogaoImg,
    name: "CAZUELITAS DE PATACÓN CON HOGAO",
    description: "3 cazuelitas de patacón, rellenas de hogao.",
    price: 15,
    foodType: CategoryEnum.ENTRADAS,
    stars: 5,
  },
  {
    id: `${CategoryEnum.ENTRADAS} - 2`,
    image: CazuelitasCamaronImg,
    name: "CAZUELITAS DE PATACÓN CON CAMARONES",
    description:
      "3 cazuelitas de patacón, rellenas de camarón en salsa de la casa.",
    price: 22,
    foodType: CategoryEnum.ENTRADAS,
    stars: 5,
  },
  {
    id: `${CategoryEnum.ENTRADAS} - 3`,
    image: CevicheChicharronImg,
    name: "CEVICHE CHICHARRÓN",
    description:
      "Lo mejor de dos mundos, la frescura del ceviche y la ternura crujiente del chicharrón.",
    price: 20,
    foodType: CategoryEnum.ENTRADAS,
    stars: 5,
  },
];

const SOPAS: Array<IDish> = [
  {
    id: `${CategoryEnum.SOPAS} - 1`,
    image: MuteImg,
    name: "MUTE BOYACENSE",
    description:
      "Sopa de mute, habas, frijol, ahuyama, pata de res, callo, costilla, picadillo de cebolla y cilantro, acompañado de arroz blanco, aguacate y arepa.",
    price: 28,
    foodType: CategoryEnum.SOPAS,
    stars: 5,
  },
  {
    id: `${CategoryEnum.SOPAS} - 2`,
    image: AjiacoImg,
    name: "AJIACO",
    description:
      "Auténtico ajiaco santafereño, acompañado de pierna pernil, arroz y aguacate.",
    price: 28,
    foodType: CategoryEnum.SOPAS,
    stars: 5,
  },
];

const ORIGEN: Array<IDish> = [
  {
    id: `${CategoryEnum.ORIGEN} - 1`,
    image: LomoMarTierraImg,
    name: "LOMO MAR Y TIERRA",
    description:
      "Medallones de lomo fino de res con borde en tocineta bañado en salsa caribeña con camarones sobre puré rústico de papa acompañado con ensaladilla de verduras salteadas.",
    price: 45,
    foodType: CategoryEnum.ORIGEN,
    stars: 5,
  },
  {
    id: `${CategoryEnum.ORIGEN} - 2`,
    image: OssobucoImg,
    name: "OSSOBUCO VINO TINTO",
    description:
      "Medallones de Ossobuco con hueso, bañados en reducción de vino tinto, servido en puré de papa con verduras salteadas.",
    price: 45,
    foodType: CategoryEnum.ORIGEN,
    stars: 5,
  },
  {
    id: `${CategoryEnum.ORIGEN} - 3`,
    image: LomoCaciqueImg,
    name: "LOMO CACIQUE",
    description:
      "Medallones de Lomo fino de res sellado en costra de pimientas (blanca, negra y verde) bañado en una reducción de mantequilla, brandy y cilantro sobre puré rústico de papa acompañado con ensaladilla de verduras salteadas.",
    price: 45,
    foodType: CategoryEnum.ORIGEN,
    stars: 5,
  },
  {
    id: `${CategoryEnum.ORIGEN} - 4`,
    image: ArrozCartageneroImg,
    name: "ARROZ CARIBEÑO",
    description:
      "Receta insignia de la casa, es un arroz cremoso con frutos del mar cocinados en salsa caribeña y base madre de verduras, una preparación inspirada en la costa Colombiana.",
    price: 45,
    foodType: CategoryEnum.ORIGEN,
    stars: 5,
  },
];

const TIPICOS: Array<IDish> = [
  {
    id: `${CategoryEnum.TIPICOS} - 1`,
    image: CocidoBoyacenseImg,
    name: "COCIDO BOYACENSE PERSONAL",
    description:
      "Porción de gallina, carne de res, carne de cerdo, chuguas, cubios, habas, arvejita, papa sabanera y mazorca, todo cocido en un rico guiso tradicional boyacense, acompañado de arroz y aguacate.",
    price: 40,
    foodType: CategoryEnum.TIPICOS,
    stars: 5,
  },
  {
    id: `${CategoryEnum.TIPICOS} - 2`,
    image: ColaResImg,
    name: "GUISO DE COLA",
    description:
      "Corte seleccionado de cola de res carnuda, cocinada en el auténtico guiso dela casa. Acompañado de yuca, papa, arroz y ensalada.",
    price: 38,
    foodType: CategoryEnum.TIPICOS,
    stars: 5,
  },
  {
    id: `${CategoryEnum.TIPICOS} - 3`,
    image: LenguaSalsaImg,
    name: "LENGUA EN SALSA",
    description:
      "Fino corte de lengua en salsa criolla. Acompañado de yuca, papa, arroz y ensalada.",
    price: 38,
    foodType: CategoryEnum.TIPICOS,
    stars: 5,
  },
  {
    id: `${CategoryEnum.TIPICOS} - 4`,
    image: SobrebarrigaImg,
    name: "SOBREBARRIGA",
    description:
      "Sobrebarriga de res dorada o en salsa criolla, acompañada de arroz, papa y ensalada.",
    price: 38,
    foodType: CategoryEnum.TIPICOS,
    stars: 5,
  },
];

const PARRILLA: Array<IDish> = [
  {
    id: `${CategoryEnum.PARRILLA} - 1`,
    image: ChurrascoImg,
    name: "CHURRASCO AL CHIMICHURRI",
    description:
      "Tierno corte de chatas en mariposa maduradas en casa, asado a la parrilla, con ensalada, y un acompañamiento a su elección (patacón, papa francesa o papa salada).",
    price: 38,
    foodType: CategoryEnum.PARRILLA,
    stars: 5,
  },
  {
    id: `${CategoryEnum.PARRILLA} - 2`,
    image: ChurrascoOrigen,
    name: "CHURRASCO ORIGEN",
    description:
      "Tierno corte de chatas en mariposa maduradas en casa, asado a la parrilla, encebollado con champiñones y queso fundido, con ensalada, y un acompañamiento a su elección (patacón, papa francesa o papa salada).",
    price: 43,
    foodType: CategoryEnum.PARRILLA,
    stars: 5,
  },
  {
    id: `${CategoryEnum.PARRILLA} - 3`,
    image: LomoCerdoImg,
    name: "LOMO DE CERDO A LA PARRILLA",
    description:
      "Lomo fino de cerdo asado a la parrilla, con ensalada, y un acompañamiento a su elección (patacón, papa francesa o papa salada).",
    price: 33,
    foodType: CategoryEnum.PARRILLA,
    stars: 5,
  },
  {
    id: `${CategoryEnum.PARRILLA} - 4`,
    image: CostillasImg,
    name: "COSTILLAS DE CERDO BBQ",
    description:
      "Corte tierno de costilla de cerdo, asado a la parrilla y bañado en salsa BBQ, con ensalada, y un acompañamiento a su elección (patacón, papa francesa o papa salada).",
    price: 38,
    foodType: CategoryEnum.PARRILLA,
    stars: 5,
  },

  {
    id: `${CategoryEnum.PARRILLA} - 5`,
    image: PechugaParrillaImg,
    name: "PECHUGA A LA PARRILA",
    description:
      "Filete de suprema de pechuga, asada a la parrilla con ensalada, y un acompañamiento a su elección (patacon, papa francesa o papa salada).",
    price: 33,
    foodType: CategoryEnum.PARRILLA,
    stars: 5,
  },
  {
    id: `${CategoryEnum.PARRILLA} - 6`,
    image: PechugaChampinonImg,
    name: "PECHUGA AL CHAMPIÑON",
    description:
      "Filete de suprema de pechuga asada a la parrilla, bañada en salsa de champiñones, con ensalada, y un acompañamiento a su elección (patacón, papa francesa o papa salada)",
    price: 38,
    foodType: CategoryEnum.PARRILLA,
    stars: 5,
  },
  {
    id: `${CategoryEnum.PARRILLA} - 7`,
    image: ParrilladaImg,
    name: "PARRILLADA",
    description:
      "200gr Carne de res, 200gr Carne de cerdo, 200gr Pechuga, Chorizo, Papa y arepa",
    price: 40,
    foodType: CategoryEnum.PARRILLA,
    stars: 5,
  },
];

const PESCADOS: Array<IDish> = [
  {
    id: `${CategoryEnum.PESCADOS} - 1`,
    image: MojarraImg,
    name: "MOJARRA",
    description:
      "Mojarra roja (Horneada, Apanada o a la Parrilla), acompañada de arroz, ensalada y patacón.",
    price: 35,
    foodType: CategoryEnum.PARRILLA,
    stars: 5,
  },
  {
    id: `${CategoryEnum.PESCADOS} - 2`,
    image: MojarraSalsaImg,
    name: "MOJARRA CRIOLLA",
    description:
      "Mojarra roja preparada en la salsa criolla, acompañada de arroz, yuca y papa con ensalada.",
    price: 35,
    foodType: CategoryEnum.PESCADOS,
    stars: 5,
  },
  {
    id: `${CategoryEnum.PESCADOS} - 3`,
    image: ViudoImg,
    name: "VIUDO DE CAPAZ",
    description:
      "Pescado de temporada en salsa criolla servido en cazuela de barro acompañado de papa, yuca, plátano, aguacate, arroz y consomé.",
    price: 40,
    foodType: CategoryEnum.PESCADOS,
    stars: 5,
  },
  {
    id: `${CategoryEnum.PESCADOS} - 4`,
    image: BagreSalsaImg,
    name: "BAGRE EN SALSA",
    description:
      "Rodaja de 500gr de bagre cocinado en la salsa de la casa. Acompañado de papa, yuca y arroz y aguacate",
    price: 40,
    foodType: CategoryEnum.PESCADOS,
    stars: 5,
  },

  {
    id: `${CategoryEnum.PESCADOS} - 5`,
    image: TruchaParrillaImg,
    name: "TRUCHA",
    description:
      "600 gr. de Trucha arcoíris bañada en la salsa de ajo o gratinada, acompañada de papa a la francesa y ensalada.",
    price: 40,
    foodType: CategoryEnum.PESCADOS,
    stars: 5,
  },
  {
    id: `${CategoryEnum.PESCADOS} - 6`,
    image: TruchaImg,
    name: "TRUCHA MARINERA",
    description:
      "Trucha arcoíris bañada en nuestra salsa inspirada en el Caribe Colombiano, acompañada de arroz, ensalada y yuca frita.",
    price: 45,
    foodType: CategoryEnum.PESCADOS,
    stars: 5,
  },
  {
    id: `${CategoryEnum.PESCADOS} - 7`,
    image: SalmonImg,
    name: "SALMÓN ORIGEN",
    description:
      "300gr de Salmón, bañado en salsa de Maracuyá o Gulupa. Acompañado de yuca dorada y ensalada.",
    price: 45,
    foodType: CategoryEnum.PESCADOS,
    stars: 5,
  },
  {
    id: `${CategoryEnum.PESCADOS} - 8`,
    image: CazuelaMariscosImg,
    name: "CAZUELA DE MARISCOS",
    description:
      "Exquisitos frutos del mar cocinados en nuestra salsa inspirada en el Caribe Colombiano con un toque de queso parmesano gratinado. Acompañada de arroz y Patacón.",
    price: 48,
    foodType: CategoryEnum.PESCADOS,
    stars: 5,
  },
];

const INFANTIL: Array<IDish> = [
  {
    id: `${CategoryEnum.INFANTIL} - 1`,
    image: IntantilImg,
    name: "INFANTIL ORIGEN",
    description:
      "Lomito de res / Pechuga a la plancha / Lomitos de pechuga apanados, acompañado de papas a la francesa y ensalada. (Incluye sorpresa)",
    price: 25,
    foodType: CategoryEnum.INFANTIL,
    stars: 5,
  },
];

export const FOOD_CATEGORIES: ICategory[] = [
  {
    position: 1,
    name: CategoryEnum.ENTRADAS,
    icon: CevicheChicharronImg,
    productsList: ENTRADAS,
  },
  {
    position: 2,
    name: CategoryEnum.SOPAS,
    icon: AjiacoImg,
    productsList: SOPAS,
  },
  {
    position: 3,
    name: CategoryEnum.ORIGEN,
    icon: LomoCaciqueImg,
    productsList: ORIGEN,
  },
  {
    position: 4,
    name: CategoryEnum.TIPICOS,
    icon: ColaResImg,
    productsList: TIPICOS,
  },
  {
    position: 5,
    name: CategoryEnum.PESCADOS,
    icon: CazuelaMariscosImg,
    productsList: PESCADOS,
  },
  {
    position: 6,
    name: CategoryEnum.PARRILLA,
    icon: LomoCerdoImg,
    productsList: PARRILLA,
  },
  {
    position: 7,
    name: CategoryEnum.INFANTIL,
    icon: IntantilImg,
    productsList: INFANTIL,
  },
];

export const COMPLETE_MENU = [
  ...ENTRADAS,
  ...SOPAS,
  ...ORIGEN,
  ...TIPICOS,
  ...PESCADOS,
  ...PARRILLA,
  ...INFANTIL,
];
