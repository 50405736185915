import { FunctionComponent } from "react";
import ingredientsImg from "../../assets/images/products/Vegetales.png";

import style from './Ingredients.module.scss';


interface IngredientsComponentProps {
    
}
 
const IngredientsComponent: FunctionComponent<IngredientsComponentProps> = () => {
    return ( 
        <section className={style.mainContainer} id="ingredients">          
            <div className={style.imageContainer}>
                <img src={ingredientsImg} alt="" />
            </div>  
            <div className={style.descriptionContainer}>
                <h2>
                    El campo Colombiano <br /> es nuestro mayor aliado.
                </h2>
                <p>
                    Valoramos profundamente la calidad y la procedencia de nuestros ingredientes. 
                    Por eso, nos enorgullece decir que somos aliados de los campesinos y ganaderos 
                    de nuestra región. Trabajamos en estrecha colaboración con ellos para garantizar 
                    que cada ingrediente que llega a nuestra cocina sea fresco, saludable y cultivado 
                    de manera responsable. Esta asociación no solo nos permite ofrecer una experiencia 
                    culinaria excepcional, sino que también contribuye al desarrollo sostenible de nuestra comunidad agrícola. 
                </p>
                <p>
                    Ven y descubre el verdadero sabor de la Colombia auténtica, 
                    respaldado por nuestro compromiso con la excelencia y la ética en la cadena de suministro.
                </p>
                <a
                    href="https://api.whatsapp.com/send?phone=573045317663"
                    target="_blank"
                    className="btn"
                >
                    Quiero reservar una mesa.
                </a>
            </div>
            
        </section>
     );
}
 
export default IngredientsComponent;