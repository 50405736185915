import { FunctionComponent, useState } from "react";
import LOGO_ORIGEN from "../../assets/images/logoOrigen.png";
import { ROUTES_LIST } from "./consts";
import { Transition } from "@headlessui/react";
import { useLocation } from "react-router";
import SocialComponent from "../SocialComponent/SocialComponent";

interface NavBarComponentProps {}

const NavBarComponent: FunctionComponent<NavBarComponentProps> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [changeHeader, setChangeHeader] = useState(false);
  const location = useLocation();

  //header change function
  const onChangeHeader = () => {
    if (window.scrollY >= 50) {
      setChangeHeader(true);
    } else {
      setChangeHeader(false);
    }
  };

  //change header by scrolling
  window.addEventListener("scroll", onChangeHeader);

  const header = document.querySelector("header");

  const setStickyHeader = () => {
    header?.classList.toggle("sticky", window.scrollY > 0);
  }

  const isHome = location.pathname == '/'

  const getBackColor = () => {
    return isHome ? 
    changeHeader ?  'white' : 'transparent' :
    changeHeader ?  'white' : 'transparent' ;  
      
  }

  const getFontColor = () => {
    return isHome ? 
    changeHeader ?  'mainBlue': 'white' :
     'mainBlue';
      
  }

  if(location.pathname != '/') setStickyHeader();

  window.addEventListener("scroll", function () {
    setStickyHeader();
  });


  return (
    <header className={`bg-${getBackColor()} text-mainBlue fixed z-50 top-0 left-0 w-full transition duration-500 h-[8%]  sm:h-[10%]  md:h-[10%] lg:h-[10%] `}>
      <nav className="my-[1%] poppins">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="w-[30%] sm:w-[10%] md:w-[10%] lg:w-[10%] flex items-center justify-center">
              <a href="/">
                <img
                  className="w-[100%]"
                  src={LOGO_ORIGEN}
                  alt="Workflow"
                />
              </a>
              
            </div>
            <div className="flex items-center ">

              <div className="hidden md:block">
                <div className="ml-10 flex items-center justify-center ">
                  {ROUTES_LIST.map(({ name, url }: any) => (
                   <a
                      key={name}
                      href={url}
                      className={`hover:bg-gray-700 text-${getFontColor()} px-3 py-2 rounded-md text-lg font-medium`}
                    >
                      {name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <SocialComponent className={`items-end hidden md:flex text-${getFontColor()}`} />
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-mainBlue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="md:hidden bg-mainBlue" id="mobile-menu">
              <div ref={ref} className="flex flex-col px-2 pt-2 pb-3  sm:px-3">
                {ROUTES_LIST.map(({ name, url }: any) => (
                  <a
                    href={url}
                    className=" text-white px-3 py-2 rounded-md text-large font-medium"
                  >
                    {name}
                  </a>
                ))}
                <SocialComponent className={`ml-10 flex items-center justify-center text-white`} />
              </div>
            </div>
          )}
        </Transition>
      </nav>
    </header>
  );
};

export default NavBarComponent;
