import { FunctionComponent, useState } from "react";
import style from './MapComponent.module.scss';

const testimonials = [
  {
    name: "Agnes Filomena",
    job: "CEO, Fashworks",
    image: "https://i.postimg.cc/mgp4pfz5/profile-image-1.png",
    testimonial:
      "La comida excelente. Lugar muy agradable para comer y compartir con familia y amigos.",
  },
  {
    name: "Albeiro Garcia",
    job: "Developer, TechCrew",
    image: "https://i.postimg.cc/PfSSwtB9/profile-image-2.png",
    testimonial:
      "Excelente la comida el lugar muy bonito.",
  },
  {
    name: "John Alex Angulo Ramos",
    job: "UI Designer, Affinity Agency",
    image: "https://i.postimg.cc/W4mnbjG9/profile-image-3.png",
    testimonial:
      "Me encanta este sitio, la comida es excelente, la atención también, solo falta música típica colombiana 😊",
  },
  {
    name: "Maria Manrique",
    job: "CEO,Zeal Wheels",
    image: "https://i.postimg.cc/xdLsJL23/profile-image-4.png",
    testimonial:
      "Excelente lugar para compartir en familia.",
  },
];

interface MapComponentProps {
    
}
 
const MapComponent: FunctionComponent<MapComponentProps> = () => {

    const [testimonialIdx, setTestimonialIdx] = useState(0);

    const handleNext = () => {
        const lastTestimonial = testimonialIdx == testimonials.length -1;
        setTestimonialIdx(lastTestimonial ? 0 : testimonialIdx + 1);
    }

    const handleBack = () => {
        const firstTestimonial = testimonialIdx == 0;
        setTestimonialIdx(firstTestimonial ? testimonials.length - 1 : testimonialIdx - 1);
    }

    return ( 
        <div className={style.mapContainer}>
            <div className={style.wrapper}>
                <div className={style.testimonialsContainer} id="testimonial-container">
                    <p>{testimonials[testimonialIdx].testimonial}</p>
                    <h3>{testimonials[testimonialIdx].name}</h3>
                    <div className={style.btnContainer}>
                        <button className={style.btnBack} onClick={handleBack}>&lt;</button>
                        <button className={style.btnNext} onClick={handleNext}>&gt;</button>
                    </div>                    
                </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15913.770219228454!2d-74.3919599!3d4.3276749!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e3f05ddf8fa019b%3A0x5558f40bc48dc95c!2sOrigen%20mesa%20y%20bar!5e0!3m2!1ses!2sco!4v1714441590784!5m2!1ses!2sco" 
                width="600" height="450" style={{border:0}} className={style.mapFrame} allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
     );
}
 
export default MapComponent;