import { FunctionComponent, useEffect, useState } from "react";
import { IDish } from "../../models/IDish";
import { stringToUpperCase } from "../../shared/utils";
import FoodItemComponent from "../FoodItemComponent/FoodItemComponent";
import SearchBarComponent from "../SearchBarComponent/SearchBarComponent";
import { COMPLETE_MENU, FOOD_CATEGORIES } from "./consts";
import SkeletonComponent from "./SkeletonComponent";
import styles from './FoodsComponent.module.css';
import classNames from "classnames";
import DishCategories from "../DishCategories/DishCategories";
import { CategoryEnum } from "../../models/ICategory";
import DishComponent from "../Dish/Dish";

interface FoodsComponentProps {

}

const FoodsComponent: FunctionComponent<FoodsComponentProps> = () => {
  const [loading, setLoading] = useState(false)
  const [changeHeader, setChangeHeader] = useState(false);
  const [foods, setfoods] = useState(COMPLETE_MENU);  
  const [categorySelected, setCategorySelected] = useState(CategoryEnum.ENTRADAS);
 
  const handleSearch = ({ target: { value } }: any) => {
    const foodsFiltered = COMPLETE_MENU.filter((food: IDish) => JSON.stringify(food).includes(value));
    setfoods(foodsFiltered);
  };

  const handleSelectCategory = (newCategory: CategoryEnum) => {
    const elem: any = document.getElementById(newCategory);
    const isMobile = window.matchMedia("(max-width: 767px)").matches;
    const newY = elem.offsetTop - (isMobile ? 180 : 240);
    window.scrollTo(0,newY)
    setCategorySelected(newCategory);
  }

  
  //header change function
  const onChangeHeader = () => {
    if (window.scrollY >= 50) {
      setChangeHeader(true);
    } else {
      setChangeHeader(false);
    }
  };


   //change header by scrolling
  window.addEventListener("scroll", onChangeHeader);

  const header = document.getElementById("categoriesList");
  const productsContainer = document.getElementById("productsContainer");


  window.addEventListener("scroll", function () {
    header?.classList.toggle(styles.sticky, window.scrollY > 0);
  });


  return (
    <section className={classNames(styles.bgImage, 'my-12 max-w-screen-2xl mx-auto px-1')}>
      <div id="categoriesList">
        {/* <SearchBarComponent handleChange={handleSearch} /> */}
        <DishCategories categorySelected={categorySelected} handleSelectCategory={handleSelectCategory}/>
      </div>
      

     <div id="productsContainer" className={styles.productsContainer}>
      {FOOD_CATEGORIES.map((productCategory) => {
          const {position, name, productsList} = productCategory;

          return (
            <div id={name} className={styles.productList} key={productCategory.name}>
              <h1>{name}</h1>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 mt-12">
                {productsList.map((item) => (
                  loading ? <SkeletonComponent key={`${name}-${position}`} /> : <DishComponent key={item.id} dish={item} />
                ))}
              </div>
            </div>
          )
        })}
     </div>

    </section>

  )
}

export default FoodsComponent;