import { IDish } from "./IDish";

export interface ICategory {
    name: CategoryEnum;
    position: number;
    icon: any;
    productsList: Array<IDish>
}

export enum CategoryEnum {
    ENTRADAS = 'ENTRADAS',
    SOPAS = 'SOPAS',
    TIPICOS = 'TIPICOS',
    PESCADOS = 'PESCADOS',
    PARRILLA = 'PARRILLA',  
    INFANTIL = 'INFANTIL',
    ORIGEN = 'PREARACIONES CON ORIGEN'
}

