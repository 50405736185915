import { FunctionComponent, useState } from "react";
import styles from './DishCategories.module.scss'
import classNames from "classnames";
import { stringToUpperCase } from "../../shared/utils";
import { FOOD_CATEGORIES } from "../FoodsComponent/consts";
import { CategoryEnum } from "../../models/ICategory";



interface DishCategoriesProps {
    categorySelected: string;
    handleSelectCategory: (newCategory: CategoryEnum) => void;
}
 
const DishCategories: FunctionComponent<DishCategoriesProps> = ({categorySelected, handleSelectCategory}) => {

    const isActiveTab = (categoryName: any) => categorySelected === categoryName;  

    const header = document.getElementById("categoriesList");


    window.addEventListener("scroll", function () {
        header?.classList.toggle(styles.sticky, window.scrollY > 0);
    });
    
    return ( 
        <div id="categoriesList" className={classNames(styles.scrollinWrapper) }>
            {FOOD_CATEGORIES.map((category) => (
                <a 
                    key={JSON.stringify(category)}
                    className={
                        classNames(styles.card, isActiveTab(category.name) ?
                            styles.cardSelected :
                            "menu_tab")}
                    onClick={() => handleSelectCategory(category.name)}>
                    <div className={styles.imgContainer}>
                        <img src={category.icon}/>
                    </div>
                    <div className={styles.dishContent}>
                        <h2>{stringToUpperCase(category.name)}</h2>
                    </div>                
                </a>
                
            ))}
            
        </div>
     );
}
 
export default DishCategories;