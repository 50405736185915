import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import style from "./BannerComponent.module.scss";

interface BannerComponentProps {}

const BannerComponent: FunctionComponent<BannerComponentProps> = () => {
  const sr = ScrollReveal({
    distance: "60px",
    duration: 2500,
    reset: true,
  });

  sr.reveal("#homeContainer", { delay: 200, origin: "left" });
  sr.reveal(".home-img", { delay: 200, origin: "right" });
  sr.reveal(".container, .about, .menu, .contact, #ingredients", {
    delay: 200,
    origin: "bottom",
  });

  return (
    <div className={style.homeContainer}>
      <div className={style.homeText} id="homeContainer">
        <h1>En Origen cada receta tiene una historia que contar.</h1>
        <p>
          Encuentra en Origen gran variedad de platos típicos Colombianos,
          pescados y parrilla.
        </p>
        <div className={style.actionContainer}>
          <a href="#menu" className="btn backgroundRed">
            Quiero ver el menú!
          </a>
        </div>
      </div>
    </div>
  );
};

export default BannerComponent;
