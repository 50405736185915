import { FunctionComponent } from "react";
import aboutImg from "./../../assets/images/about.jpg";
import style from './About.module.scss';

interface AboutComponentProps {}

const AboutComponent: FunctionComponent<AboutComponentProps> = () => {
  return (
    <>
      <section className="about" id="about">
        <div className="about-img">
          <img src={aboutImg} />
        </div>

        <div className="about-text">
          <h2>
            Nuestras preparaciones tienen historia.
          </h2>
          <p>
            Cada plato que servimos en nuestro restaurante lleva consigo una historia única y cautivadora, 
            una historia que habla de tradición, pasión y dedicación. 
            Desde la primera semilla plantada en la tierra hasta el momento en que llega a tu mesa, 
            cada ingrediente ha sido cuidadosamente seleccionado y preparado con amor y respeto 
            por la autenticidad de nuestra cocina colombiana. Detrás de cada plato hay manos trabajadoras, 
            recetas transmitidas de generación en generación y un profundo arraigo a nuestras raíces culturales. 
          </p>
          <a
            href="https://api.whatsapp.com/send?phone=573045317663"
            target="_blank"
            className="btn"
          >
            Quiero reservar una mesa.
          </a>
        </div>
      </section>
    </>
  );
};

export default AboutComponent;
