export const ROUTES_LIST = [
  {
    name: '¿Quienes Somos?',
    url: '#categories',
  },
  {
    name: 'Menú',
    url: '#menu',
  },
  // {
  //   name: 'Contacto',
  //   url: '#contact',
  // },
]